import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import CareersHero from '../components/organisms/CareersHero';
import CareersLocationsSection from '../components/organisms/CareersLocationsSection';
import DiversitySection from '../components/organisms/DiversitySection';
import HiringProcessSection from '../components/organisms/HiringProcessSection';
import LifeAtSpirableSection from '../components/organisms/LifeAtSpirableSection';
import MissionSection from '../components/organisms/MissionSection';
import OpenPositions from '../components/organisms/OpenPositions';
import PerksSection from '../components/organisms/PerksSection';

const CareersPage = ({ data: { data } }) => (
  <Layout>
    <Seo post={data} />

    <CareersHero {...data.careers.careersHero} />
    <MissionSection {...data.careers.missionSection} />
    <LifeAtSpirableSection {...data.careers.lifeAtSpirableSection} />
    <CareersLocationsSection {...data.careers.careersLocations} />
    <PerksSection {...data.careers.perksAndBenefitsSection} />
    <HiringProcessSection {...data.careers.hiringProcessSection} />
    <DiversitySection {...data.careers.diversitySection} />
    <OpenPositions {...data.careers.openPositions} />
  </Layout>
);

export const pageQuery = graphql`
  query CareersPageQuery {
    data: wpPage(slug: { eq: "careers" }) {
      title
      ...SEO
      careers {
        careersHero {
          title
          image {
            ...Image
          }
          glassdoorRating
          glassdoorRatingText
          seeOpenPositionsLabel
        }
        missionSection {
          lead
          title
          body
        }
        lifeAtSpirableSection {
          lead
          title
          body1
          body2
          photo1 {
            ...Image
          }
          photo2 {
            ...Image
          }
        }
        careersLocations {
          image {
            ...Image
          }
          body
          icon {
            ...Image
          }
          viewLocationsLabel
        }
        perksAndBenefitsSection {
          lead
          title
          perks {
            title
            body
            icon {
              ...Image
            }
          }
        }
        hiringProcessSection {
          lead
          title
          body
          ctaTitle
          steps {
            title
            icon {
              ...Image
            }
          }
        }
        diversitySection {
          title
          columns {
            body
          }
          images {
            image {
              ...Image
            }
          }
          footerTitle
          footerBody
        }
        openPositions {
          lead
          title
          body
        }
      }
    }
  }
`;

export default CareersPage;
