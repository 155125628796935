import React from 'react';

interface CareersLocationsSectionProps {
  image: {
    sourceUrl: string;
    altText: string;
  };
  body: string;
  icon: {
    sourceUrl: string;
    altText: string;
  };
  viewLocationsLabel: string;
}

const CareersLocationsSection: React.FC<CareersLocationsSectionProps> = ({
  image,
  body,
  icon,
  viewLocationsLabel,
}) => (
  <section
    className="relative bg-center bg-cover h-120 lg:h-auto lg:bg-none"
    style={{ backgroundImage: `url(${image?.sourceUrl})` }}
  >
    <img
      src={image?.sourceUrl}
      alt={image?.altText}
      className="hidden w-full h-auto lg:block"
    />

    <div className="absolute left-0 right-0 z-10 bottom-8 lg:bottom-24">
      <div className="u-container">
        <div className="items-center p-8 bg-white rounded-md md:p-12 lg:flex lg:mx-20 shadow-locations">
          <div className="items-center md:flex">
            <img
              src={icon.sourceUrl}
              alt={icon.altText}
              className="w-auto h-8 mb-4 md:h-12 md:mb-0 md:mr-9"
            />
            <div
              className="text-sm font-medium lg:mr-12 lg:text-lg"
              dangerouslySetInnerHTML={{ __html: body }}
            />
          </div>
          <a href="/contact" className="flex-shrink-0 mx-auto mt-6 md:mt-8 u-btn u-btn--primary lg:mx-0 lg:mt-0">
            {viewLocationsLabel}
          </a>
        </div>
      </div>
    </div>
  </section>
);

export default CareersLocationsSection;
