import React from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { create } from '@lottiefiles/lottie-interactivity';


class RibbonAnimation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { lottie: null };
    this.myRef = React.createRef(); // 1. create a reference for the lottie player
    this.addScroll = this.addScroll.bind(this);
  }

  addScroll() {
    create({
      mode: 'scroll',
      player: this.state.lottie,
      actions: [
        {
          visibility: [0, .8],
          type: 'seek',
          frames: [0, 100],
        },
      ],
    });
  }

  render() {
    return (
      <div className="overflow-hidden">
        <div className="-mx-8">
          <Player
            ref={this.myRef}
            lottieRef={instance => {
              this.setState({ lottie: instance });
            }}
            onEvent={event => {
              if (event === 'load') {
                this.addScroll();
              }
            }}
            id="ribbonanimation"
            mode="normal"
            src="/images/spirable-careers-ribbon.json"
            style={{width:'calc(115vw)'}}
          />
        </div>
      </div>
    );
  }
}

export default RibbonAnimation;
