import React from 'react';
import StarIcon from '../atoms/StarIcon';
import FadeIn from '../molecules/FadeInWhenVisible';
import Image from '../base/Image';
interface CareersHeroProps {
  title: string;
  image: {
    sourceUrl: string;
    altText: string;
  };
  glassdoorRating: number;
  glassdoorRatingText: string;
  seeOpenPositionsLabel: string;
}

const CareersHero: React.FC<CareersHeroProps> = ({
  title,
  image,
  glassdoorRating,
  glassdoorRatingText,
  seeOpenPositionsLabel,
}) => (
  <section className="flex flex-col items-center pt-16 lg:pt-32 pb-14 lg:pb-28 u-container-max">
    <div className="px-5 text-center md:px-0">
      <FadeIn className="inline-flex items-center px-6 py-4 mx-auto mb-8 rounded-lg shadow-glassdoor">
        <div className="flex items-center mr-3 space-x-1.5">
          {Array.from({ length: glassdoorRating }).map((_, index) => (
            <StarIcon key={index} />
          ))}
        </div>
        <div
          className="flex pt-1 text-sm font-semibold leading-none"
          dangerouslySetInnerHTML={{ __html: glassdoorRatingText }}
        />
      </FadeIn>
      <FadeIn transition={{ delay: 0.2 }}>
        <h1
          className="max-w-2xl mx-auto mb-10 text-center u-h1"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </FadeIn>
      <FadeIn transition={{ delay: 0.4 }}>
        <a href="#positions" className="mx-auto mb-16 rounded-none md:mb-32 u-btn u-btn--primary">
          {seeOpenPositionsLabel}
        </a>
      </FadeIn>
    </div>
    <FadeIn transition={{ delay: 0.4 }} className="w-full">
      <Image data={image} className="w-full h-auto" />
    </FadeIn>
  </section>
);

export default CareersHero;
