import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useWindowSize } from 'react-use';
import ArrowRight from '../atoms/ArrowRight';
import PerksSection from './PerksSection';

interface HiringProcessSectionProps {
  lead: string;
  title: string;
  body: string;
  ctaTitle: string;
  steps: {
    title: string;
    icon: {
      sourceUrl: string;
      altText: string;
    };
  }[];
}

const HiringProcessSection: React.FC<HiringProcessSectionProps> = ({
  lead,
  title,
  body,
  ctaTitle,
  steps,
}) => {
  const [offset, setOffset] = useState(0);
  const { width } = useWindowSize();
  const cardWidth = width < 768 ? 408 : 504;

  return (
    <section
      className="py-16 overflow-hidden bg-cover md:py-32"
      style={{
        backgroundImage:
          'linear-gradient(159.3deg, #F7E69A -48.7%, #EE7272 80.57%)',
      }}
    >
      <div className="u-container">
        <div className="max-w-2xl">
          <div className="text-white u-s1">{lead}</div>
          <h2 className="text-white u-h2">{title}</h2>
          <div
            className="prose text-white md:prose-lg"
            dangerouslySetInnerHTML={{ __html: body }}
          />
        </div>

        <div className="hidden mt-10 mb-16 md:flex lg:justify-end lg:-mt-18 lg:mb-28">
          <div className="flex items-center space-x-2.5 mt-5 lg:mt-0">
            <button
              disabled={offset === 0}
              onClick={() => setOffset((o) => o - 1)}
              className="flex items-center justify-center text-white border border-white rounded-full w-7 h-7 border-opacity-30 focus:outline-none"
            >
              <ArrowRight
                className={`transform rotate-180 -ml-px ${
                  offset === 0 && 'opacity-30 pointer-events-none'
                }`}
              />
            </button>
            <button
              disabled={offset === steps.length - 1}
              onClick={() => setOffset((o) => o + 1)}
              className="flex items-center justify-center text-white border border-white rounded-full w-7 h-7 border-opacity-30 focus:outline-none"
            >
              <ArrowRight
                className={`ml-px ${
                  offset === steps.length - 1 &&
                  'opacity-30 pointer-events-none'
                }`}
              />
            </button>
          </div>
        </div>

        <div className="px-5 mt-12 -mx-5 overflow-visible overflow-x-scroll md:overflow-x-visible md:mt-0 md:mx-0 md:px-0 no-scrollbar">
          <motion.div
            className="flex space-x-6 flex-nowrap lg:space-y-0"
            animate={{ x: -offset * cardWidth }}
            transition={{ type: 'tween', ease: 'easeInOut' }}
          >
            {steps.map((step, index) => (
              <div
                key={index}
                className="relative flex-grow-0 flex-shrink-0 w-64 px-8 py-8 pt-24 bg-white select-none md:pt-40 md:py-20 md:px-12 md:w-120 shadow-process-card"
              >
                <img
                  src={step.icon.sourceUrl}
                  alt={step.icon.altText}
                  className="absolute w-12 h-auto top-10 right-10"
                />
                <div className="mb-2.5 text-primary-red text-lg font-semibold leading-8">
                  {index + 1}.
                </div>
                <h3 className="u-h5">{step.title}</h3>
              </div>
            ))}
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default HiringProcessSection;
