import React from 'react';

const StarIcon: React.FC = () => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.28404 -1.52588e-05L10.7602 4.95237L15.7126 5.57141L12.312 9.38475L13.2364 14.8571L8.28404 12.3809L3.33166 14.8571L4.26436 9.38475L0.855469 5.57141L5.80785 4.95237L8.28404 -1.52588e-05Z"
      fill="#0CAA41"
    />
  </svg>
);

export default StarIcon;
