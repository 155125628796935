import React from 'react';

interface DiversitySectionProps {
  title: string;
  columns: { body: string }[];
  images: { image: { sourceUrl: string; altText: string } }[];
  footerTitle: string;
  footerBody: string;
}

const DiversitySection: React.FC<DiversitySectionProps> = ({
  title,
  columns,
  images,
  footerTitle,
  footerBody,
}) => (
  <section className="pt-16 overflow-hidden md:pt-32">
    <div className="overflow-visible u-container-max">
      <div className="px-5 md:px-0">
        <h2 className="max-w-lg mb-8 md:mb-16 u-h2">{title}</h2>

        <div className="justify-between mb-16 space-y-6 md:mb-24 lg:flex lg:space-x-10 lg:space-y-0">
          {columns.map(({ body }, index) => (
            <div
              key={index}
              className="flex items-start flex-1 flex-shrink-0 md:block"
            >
              <img
                src="/images/check-badge.png"
                alt=""
                className="h-auto mb-5 mr-4 w-7 md:mr-0"
              />
              <p className="leading-normal md:leading-8 md:text-lg text-body-copy">
                {body}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col items-center mb-16 md:flex-row flex-nowrap space-y-15 md:space-y-0 md:space-x-14 md:mb-52">
        {images.map(({ image }, index) => (
          <img
            key={index}
            src={image?.sourceUrl}
            alt={image?.altText}
            className={`flex-grow-0 flex-shrink-0 w-full md:w-auto h-auto ${
              index % 2 === 0 ? 'md:h-120' : 'md:h-64'
            }`}
          />
        ))}
      </div>
    </div>

    <div className="relative flex flex-col-reverse lg:block">
      <img
        src="/images/diversity.png"
        alt=""
        className="w-full h-auto lg:w-1/2"
      />
      <div className="top-0 left-0 right-0 lg:absolute">
        <div className="justify-end mb-16 lg:flex u-container lg:mb-0">
          <div className="w-full lg:w-1/2 lg:pl-24 lg:mt-28">
            <div className="max-w-lg">
              <h3 className="mb-4 text-3xl font-semibold md:text-4.5xl leading-tighter md:mb-7">
                {footerTitle}
              </h3>
              <div
                className="prose md:prose-lg"
                dangerouslySetInnerHTML={{ __html: footerBody }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default DiversitySection;
