import React from 'react';
import useFetch from 'use-http';
import ArrowRight from '../atoms/ArrowRight';

interface OpenPositionsProps {
  lead: string;
  title: string;
  body: string;
}

interface OpenPosition {
  id: number;
  slug: string;
  status: string;
  state_code: string;
  title: string;
  description: string;
  location: string;
  city: string;
  country: string;
  careers_url: string;
  careers_apply_url: string;
  department: string;
}

const OpenPositions: React.FC<OpenPositionsProps> = ({ lead, title, body }) => {
  const { data } =
    typeof window !== 'undefined'
      ? useFetch<{ offers: OpenPosition[] }>(
          'https://spirable.recruitee.com/api/offers/',
          [],
          [],
        )
      : {};

  return (
    <section id="positions" className="py-20 bg-tertiary lg:py-40">
      <div className="justify-between lg:flex space-y-14 lg:space-y-0 lg:space-x-28 u-container">
        <div className="w-full lg:w-4/12">
          <div className="u-s1">{lead}</div>
          <h2 className="u-h2">{title}</h2>
          <div className="prose" dangerouslySetInnerHTML={{ __html: body }} />
        </div>
        <div className="w-full lg:w-8/12 lg:pt-0 space-y-9">
          {data?.offers?.map((role: OpenPosition) => (
            <a
              href={role.careers_url}
              target="_blank"
              rel="noopener noreferrer"
              key={role.id}
              className="flex items-center justify-between transition border-b border-black border-opacity-10 pb-7 hover:border-primary-red open-position-button"
            >
              <div>
                <h3 className="u-h4 mb-1.5">{role.title}</h3>
                <div className="flex items-center space-x-4 leading-7 text-primary-red">
                  <div>{role.location}</div>
                  <div className="w-1 h-1 bg-primary-red" />
                  <div>{role.department}</div>
                </div>
              </div>
              <ArrowRight className="w-5 h-auto text-black transition" />
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OpenPositions;
