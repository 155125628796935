import { string } from 'prop-types';
import React from 'react';

interface PerksSectionProps {
  lead: string;
  title: string;
  perks: {
    title: string;
    body: string;
    icon: {
      sourceUrl: string;
      altText: string;
    };
  }[];
}

const PerksSection: React.FC<PerksSectionProps> = ({ lead, title, perks }) => (
  <section className="py-20 text-center u-container lg:py-32 md:text-left">
    <div className="max-w-full mb-12 lg:mb-20 md:w-120">
      <div className="u-s1">{lead}</div>
      <h2 className="u-h2">{title}</h2>
    </div>

    <div className="grid grid-cols-1 gap-10 sm:grid-cols-2 md:grid-cols-4 md:gap-12">
      {perks.map((perk, index) => (
        <div
          key={index}
          className="w-full lg:pr-16"
        >
          <img
            src={perk.icon.sourceUrl}
            alt={perk.icon.altText}
            className="inline-block w-auto mb-5 h-9"
          />
          <h3 className="md:mb-2 u-h5">{perk.title}</h3>
          <p className="leading-7 text-body-copy">{perk.body}</p>
        </div>
      ))}
    </div>
  </section>
);

export default PerksSection;
