import React from 'react';
import RibbonAnimation from '../organisms/RibbonAnimation';

interface MissionSectionProps {
  lead: string;
  title: string;
  body: string;
}

const MissionSection: React.FC<MissionSectionProps> = ({
  lead,
  title,
  body,
}) => (
  <>
    <section className="u-container">
      <div className="flex flex-col mb-20 space-y-4 lg:flex-row lg:space-x-20 lg:space-y-0">
        <div className="w-full lg:w-1/2 lg:pr-20">
          <div className="u-s1">{lead}</div>
          <h2 className="u-h2">{title}</h2>
        </div>
        <div
          className="w-full prose md:prose-lg lg:w-1/2 lg:pr-20 text-body-copy"
          dangerouslySetInnerHTML={{ __html: body }}
        />
      </div>
    </section>
    <div className="w-full h-auto mb-0 md:-mt-12 md:mb-12">
      <RibbonAnimation />
    </div>
    {/* <img
      src="/images/careers-spiral.png"
      alt=""

    /> */}
  </>
);

export default MissionSection;
