import React from 'react';
import Image from '../base/Image';
import FadeIn from '../molecules/FadeInWhenVisible';
interface LifeAtSpirableSectionProps {
  lead: string;
  title: string;
  body1: string;
  body2: string;
  photo1: {
    sourceUrl: string;
    altText: string;
  };
  photo2: {
    sourceUrl: string;
    altText: string;
  };
}

const LifeAtSpirableSection: React.FC<LifeAtSpirableSectionProps> = ({
  lead,
  title,
  body1,
  body2,
  photo1,
  photo2,
}) => (
  <section className="pt-20 pb-16 u-container-max">
    <div className="flex flex-col justify-between mb-12 space-y-12 lg:flex-row md:mb-18 lg:space-y-0">
      <FadeIn className="w-full px-5 lg:w-5/12 md:px-0">
        <div className="u-s1">{lead}</div>
        <h2 className="u-h2">{title}</h2>
        <div
          className="prose md:prose-lg text-body-copy"
          dangerouslySetInnerHTML={{ __html: body1 }}
        />
      </FadeIn>
      <FadeIn transition={{ delay: 0.1 }} className="w-full lg:px-32 lg:w-7/12">
        <Image data={photo1} className="w-full h-auto" />
      </FadeIn>
    </div>
    <div className="flex flex-col-reverse justify-between lg:flex-row md:mb-18">
      <FadeIn
        transition={{ delay: 0.1 }}
        className="hidden w-full lg:w-5/12 lg:block"
      >
        <Image data={photo2} className="w-full h-auto" />
      </FadeIn>
      <FadeIn className="w-full px-5 lg:pt-32 lg:w-7/12 lg:px-32 lg:pb-0 md:px-0">
        <div
          className="prose md:prose-lg text-body-copy"
          dangerouslySetInnerHTML={{ __html: body2 }}
        />
      </FadeIn>
    </div>
  </section>
);

export default LifeAtSpirableSection;
